import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSocialMedia } from '@/api/social';

export const fetchSocialMedia = createAsyncThunk(
  'socialMedia/fetchSocialMedia',
  async () => {
    const { data } = await getSocialMedia();

    return data.socials;
  },
);

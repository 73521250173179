import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const MainLayout = ({ children, hasNavbar }) => (
  <Box w='full' h='full' pt={hasNavbar && 16}>
    <Box h='full' w='fit-content' mx='auto'>
      {children}
    </Box>
  </Box>
);

MainLayout.defaultProps = {
  hasNavbar: false,
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hasNavbar: PropTypes.bool,
};

export default MainLayout;

export const CATEGORIES = t => ({
  Actor: t('common:Actor'),
  Model: t('common:Model'),
  Artist: t('common:Artist'),
  SportsTeam: t('common:Sports Team'),
  Influencer: t('common:Influencer'),
  Gamer: t('common:Gamer'),
  Athlete: t('common:Athlete'),
  Brand: t('common:Brand'),
});

export const MAX_AVATAR_SIZE_BYTES = 1000000;

export const MAX_POST_SIZE_BYTES = 15000000;

export const ARTIST_STATUS = {
  active: 'ACTIVE',
  pending: 'PENDING',
  disabled: 'DISABLED',
  toBeDeleted: 'TO_BE_DELETED',
};

export const ARTIST_POSTS_CATEGORIES = {
  mostEngaged: 'MOST_ENGAGED',
  mostLiked: 'MOST_LIKED',
};

export const ARTIST_STATISTICS_DATA = [
  'posts',
  'followers',
  'comments',
  'shares',
  'impressions',
  'likes',
];

export const PROVINCES = [
  'anhui',
  'beijing',
  'chongqing',
  'fujian',
  'gansu',
  'guangdong',
  'guizhou',
  'hainan',
  'hebei',
  'heilongjiang',
  'henan',
  'hubei',
  'hunan',
  'jiangsu',
  'jiangxi',
  'jilin',
  'liaoning',
  'qinghai',
  'shaanxi',
  'shandong',
  'shanghai',
  'shanxi',
  'sichuan',
  'tianjin',
  'yunnan',
  'zhejiang',
];

export const ARTIST_ACTIONS = {
  approve: 'APPROVED',
  decline: 'DECLINED',
};

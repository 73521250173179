const size = {
  lg: {
    fontSize: "lg",
    px: 4,
    h: 10,
    borderRadius: "md",
  },

  md: {
    fontSize: "md",
    px: 4,
    h: 8,
    borderRadius: "md",
  },

  sm: {
    fontSize: "sm",
    px: 4,
    h: 6,
    borderRadius: "md",
  },

  xs: {
    fontSize: 'xs',
    px: 4,
    h: 5,
    borderRadius: "md",
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

const variantOutline = {
  field: {
    color: 'black',
    border: '1px solid',
    borderColor: 'gray.200',
    bg: 'inherit',

    _hover: {
      borderColor: 'gray.300',
    },
    _readOnly: {
      boxShadow: "none !important",
      userSelect: "all",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    _invalid: {
      color: 'epsilon.500',
      borderColor: 'epsilon.500',
    },
    _focus: {
      zIndex: 1,
      borderColor: 'delta.500',
    },
    _placeholder: {
      color: 'gray.400',
    },
  },
  addon: {
    border: 'none',
    bg: 'gray.100',
  },
};

const variantFilled = {
  field: {
    color: 'black',
    border: "2px solid",
    borderColor: "transparent",
    bg: 'gray.200',

    _hover: {
      bg: 'gray.200',
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    _invalid: {
      bg: 'epsilon.100',
      border: 'none',
      color: 'epsilon.500',
    },
    _focus: {
      bg: "transparent",
      borderColor: 'delta.500',
    },
    _placeholder: {
      color: 'gray.400',
    },
  },
  addon: {
    border: 'none',
    bg: 'gray.100',
  },
};

const variantFlushed = {
  field: {
    borderBottom: "1px solid",
    borderColor: "inherit",
    borderRadius: 0,
    px: 0,
    bg: "transparent",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    _invalid: {
      color: 'epsilon.500',
      borderColor: 'epsilon.500',
      boxShadow: 'none',
    },
    _focus: {
      borderColor: 'delta.500',
    },
    _placeholder: {
      color: 'gray.400',
    },
  },
  addon: {
    borderBottom: "2px solid",
    borderColor: "inherit",
    borderRadius: 0,
    px: 0,
    bg: "transparent",
  },
};

const variantUnstyled = {
  field: {
    bg: "transparent",
    px: 4,
    height: "auto",
    color: 'black',

    _placeholder: {
      color: 'gray.400'
    },

    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },

    _invalid: {
      border: '1px solid',
      color: 'epsilon.500',
      borderColor: 'epsilon.500',
    },
  },
  addon: {
    bg: "transparent",
    px: 4,
    height: "auto",
  },
};

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
}

const inputStyles = {
  variants,
  sizes,
};

export default inputStyles;
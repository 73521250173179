import { formatWithoutOffset } from '@/utils/dateUtils';

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const groupEventsByMonth = ({ events, upcoming = false }) => {
  let newEvents = [...events];

  if (upcoming)
    newEvents = events.map((event, idx) =>
      !idx ? { ...event, upcoming: true } : event,
    );

  return newEvents.reduce((acc, event) => {
    const month = formatWithoutOffset(event.date, 'MMMM');

    if (!acc[month]) acc[month] = [];

    acc[month].push(event);

    return acc;
  }, {});
};

// Compare equality of values
export const areValuesEqual = (a, b) => {
  const planeValues = array =>
    array.reduce((acc, el) => {
      acc.push(...Object.values(el));

      return acc;
    }, []);

  return JSON.stringify(planeValues(a)) === JSON.stringify(planeValues(b));
};

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { orderEnum } from '@/utils/constants/api';
import {
  getUsers,
  deleteUser,
  patchUser,
  requestExistingProject,
  resetPassword,
  disconnectProject,
  getPendingRequests,
} from '@/api/user';
import { getClient } from '@/api/clients';

export const useUsersData = ({ orderBy = orderEnum.asc, search, role }) =>
  useQuery(['users', { orderBy, search, role }], () =>
    getUsers({ orderBy, search, role }),
  );

export const useUserData = userId =>
  useQuery(['client', { userId }], () => getClient(userId));

export const useDeleteUserData = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onSettled: () => {
      queryClient.invalidateQueries('users');
    },
  });
};

export const useUserProfile = (userId, options) =>
  useQuery(
    ['user', userId],
    async () => {
      const response = await getClient(userId);

      return response.data;
    },
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

export const usePatchUserData = () => {
  const queryClient = useQueryClient();

  return useMutation(patchUser, {
    onSettled: () => {
      queryClient.invalidateQueries('user');
      queryClient.invalidateQueries('user-session');
    },
  });
};

export const useRequestExistingProject = () => {
  const queryClient = useQueryClient();

  return useMutation(requestExistingProject, {
    onSettled: () => {
      queryClient.invalidateQueries('projects');
    },
  });
};

export const useResetPassword = () => useMutation(resetPassword);

export const useDisconnectProject = () => {
  const queryClient = useQueryClient();

  return useMutation(disconnectProject, {
    onSettled: () => {
      queryClient.invalidateQueries('projects');
    },
  });
};

export const usePendingUsersData = () =>
  useQuery(['users-requests'], () => getPendingRequests());

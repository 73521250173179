import Client from '@/lib/axios';
import { orderEnum } from '@/utils/constants/api';

const axios = Client();

export const getUser = providerId => axios.get(`/users/${providerId}`);

export const patchUser = ({ id, user }) => axios.patch(`/users/${id}`, user);

export const deleteUser = id => axios.delete(`/users/${id}`);

export const sendInvite = async ({ email, role, isCoupon, resend = 0 }) =>
  axios.post(`users/invite?resend=${resend}`, { email, role, isCoupon });

export const getUserArtists = async userId =>
  axios.get(`/users/${userId}/artists`);

export const getUsers = async ({ orderBy = orderEnum.asc, role, search }) =>
  axios.get('/users', {
    params: { orderBy, role, ...(search && { search }) },
  });

export const requestExistingProject = async ({ userId, projectId }) =>
  axios.post(`/users/${userId}/artists`, { projectId });

export const getManagerProjects = async id => axios.get(`/users/${id}/artists`);

export const manageProjectRequest = async ({ id, projectId, action }) =>
  axios.post(`/users/${id}/artists/manage`, {
    projectId,
    action,
  });

export const resetPassword = () => axios.post('/users/reset-password');

export const disconnectProject = ({ id, projectId }) =>
  axios.delete(`/users/${id}/artists`, { data: { projectId } });

export const getPendingRequests = () => axios.get(`/users/requests`);

import axios from 'axios';
import getConfig from 'next/config';
import { getSession } from 'next-auth/react';

import { logout } from '@/utils/Auth';

let axiosInstance;

const Client = () => {
  if (axiosInstance !== undefined) {
    return axiosInstance;
  }

  const { publicRuntimeConfig } = getConfig();

  axiosInstance = axios.create({
    baseURL: publicRuntimeConfig.API_URL,
  });

  // Request interceptor to send the access token to the API
  axiosInstance.interceptors.request.use(
    async req => {
      const session = await getSession({ req });
      const accessToken = session?.accessToken;

      req.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      };

      return req;
    },
    error => {
      Promise.reject(error);
    },
  );

  // Request interceptor for redirecting if an unauthorized error occurs
  axiosInstance.interceptors.response.use(
    res => res,
    err => {
      if (err.response.status === 401) {
        // delete all previous session data if it exists
        logout();
      }

      return Promise.reject(err.response);
    },
  );

  return axiosInstance;
};

export default Client;

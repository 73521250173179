import { createSlice } from '@reduxjs/toolkit';
import { inviteUser } from './asyncAction';

const initialState = {
  invite: null,
  isLoading: false,
  errorMessage: '',
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    clearInvite() {
      return initialState;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(inviteUser.pending, state => {
      state.invite = null;
      state.errorMessage = '';
      state.isLoading = true;
    });
    addCase(inviteUser.fulfilled, (state, { payload }) => {
      state.invite = payload;
      state.errorMessage = null;
      state.isLoading = false;
    });
    addCase(inviteUser.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export const { clearInvite, clearResend } = inviteSlice.actions;

export default inviteSlice.reducer;

export const USER_ROLES = {
  ndsManager: 'ndsManager',
  superAdmin: 'superAdmin',
  eggAdmin: 'eggAdmin',
  artistManager: 'artistManager',
  eggMembers: 'eggMembers',
};

export const USER_ROLES_IDS = {
  superAdmin: 1,
  eggAdmin: 2,
  ndsManager: 3,
  artistManager: 4,
};

export const SUBSCRIPTION_STATUS = {
  incomplete: 'INCOMPLETE',
  failedPayment: 'PAYMENT_FAILED',
  active: 'ACTIVE',
  canceled: 'CANCELED',
  inactive: 'INACTIVE',
  inactiveTrial: 'INACTIVE_TRIAL',
};

export const USER_STATUS = {
  invited: 'INVITED',
  signupCompleted: 'SIGN_UP_COMPLETED',
  onboardingCompleted: 'ONBOARDING_COMPLETED',
  inactive: 'INACTIVE',
};

export const computeRole = t => ({
  [USER_ROLES.ndsManager]: {
    label: t('common:dataProvider'),
    value: USER_ROLES.ndsManager,
  },
  [USER_ROLES.superAdmin]: {
    label: t('common:superAdmin'),
    value: USER_ROLES.superAdmin,
  },
  [USER_ROLES.eggAdmin]: {
    label: t('common:admin'),
    value: USER_ROLES.eggAdmin,
  },
  [USER_ROLES.artistManager]: {
    label: t('common:artistManager'),
    value: USER_ROLES.artistManager,
  },
});

export const CLIENT_STATUS = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  disabled: 'DISABLED',
  firstRun: 'FIRST_RUN',
};

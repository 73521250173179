import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUser, postUser, patchUser } from '@/api/user';

import { STATUS_ERROR } from '@/utils/constants/errors';

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getUser(id);

      return data;
    } catch (err) {
      if (err.status === STATUS_ERROR.notFound) {
        return rejectWithValue(err.status);
      }

      return rejectWithValue(err);
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ id, user }) => {
    const { data } = await patchUser({ id, user });

    return data;
  },
);

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchTeamMembers,
  updateTeamMember,
  removeTeamMember,
} from './asyncAction';

const initialState = {
  users: null,
  isLoading: false,
  errorMessage: '',
  updateSuccessful: false,
  deleteSuccessful: false,
};

const teamMembersSlice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {
    clearTeamMembers() {
      return initialState;
    },
  },
  extraReducers: ({ addCase }) => {
    // Fetch team members
    addCase(fetchTeamMembers.pending, state => {
      state.users = null;
      state.isLoading = true;
      state.errorMessage = '';
      state.updateSuccessful = false;
      state.deleteSuccessful = false;
    });
    addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
      state.users = payload?.users;
      state.isLoading = false;
    });
    addCase(fetchTeamMembers.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });

    // Update team member
    addCase(updateTeamMember.pending, state => {
      state.errorMessage = '';
      state.isLoading = true;
      state.updateSuccessful = false;
    });
    addCase(updateTeamMember.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
      state.updateSuccessful = true;
    });
    addCase(updateTeamMember.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });

    // Remove team member
    addCase(removeTeamMember.pending, state => {
      state.errorMessage = '';
      state.isLoading = true;
      state.deleteSuccessful = false;
    });
    addCase(removeTeamMember.fulfilled, state => {
      state.isLoading = false;
      state.deleteSuccessful = true;
    });
    addCase(removeTeamMember.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export const { clearTeamMembers } = teamMembersSlice.actions;

export default teamMembersSlice.reducer;

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  localeDetection: false,
  pages: {
    '*': ['common'],
    'rgx:/*/admin': ['admin', 'invite', 'artist', 'registration'],
    'rgx:/*/projects/*': ['artist'],
    '/registration': ['registration', 'admin', 'artist'],
    'rgx:/*/manager/*': [
      'admin',
      'artist',
      'common',
      'registration',
      'subscription',
    ],
  },
  logBuild: false,
};

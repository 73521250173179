import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendInvite } from '@/api/user';

export const inviteUser = createAsyncThunk(
  'invite/inviteUser',
  async ({ email, isCoupon, role }) => {
    const { data } = await sendInvite({ email, isCoupon, role });

    return data;
  },
);

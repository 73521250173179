const baseStyle = {
  container: {
    fontSize: '10px',
    fontWeight: 'normal',
    py: 0,
    px: 0.5,
  },
};

const variantSubtle = (props) => {
  const { colorScheme } = props;

  return {
    container: {
      bg: `${colorScheme}.100`,
      color: 'black',
    },
  };
};

const variants = {
  subtle: variantSubtle,
};

const defaultProps = {
  variant: 'subtle',
  colorScheme: 'alpha',
};

const TagStyles = {
  baseStyle,
  variants,
  defaultProps,
};

export default TagStyles;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEvents, upsertEvent, deleteEvent } from '@/api/events';

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async ({ page, pageSize, upcoming }) => {
    const { data } = await getEvents({ page, pageSize, upcoming });

    return data;
  },
);

export const uploadEvent = createAsyncThunk(
  'events/uploadEvent',
  async event => {
    await upsertEvent(event);

    const { data } = await getEvents({});

    return data;
  },
);

export const removeEvent = createAsyncThunk(
  'events/removeEvent',
  async eventId => {
    await deleteEvent(eventId);

    const { data } = await getEvents({});

    return data;
  },
);

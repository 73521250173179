export const orderEnum = {
  asc: 'asc',
  desc: 'desc',
};

export const EVENTS_PAGE_SIZE = 7;

export const FILES_FORMAT = {
  CSV: '.csv',
};

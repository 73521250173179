export const getArtists = state => state.admin.artists;
export const getIsLoading = state => state.admin.isLoading;
export const getError = state => state.admin.errorMessage;

export const getArtistsStatistics = state => state.admin.statistics.statistics;
export const getIsLoadingStatistics = state => state.admin.statistics.isLoading;
export const getStatisticsError = state => state.admin.statistics.errorMessage;
export const getStatisticsUpsertSuccess = state =>
  state.admin.statistics.upsertSuccessful;
export const getStatisticsUpsertError = state =>
  state.admin.statistics.upsertError;

export const getArtistsPosts = state => state.admin.posts.posts;
export const getIsLoadingPosts = state => state.admin.posts.isLoading;
export const getPostsError = state => state.admin.posts.errorMessage;
export const getPostsUpsertSuccessful = state =>
  state.admin.posts.upsertSuccessful;
export const getPostsUpsertError = state => state.admin.posts.upsertError;
export const getPostsDeleted = state => state.admin.posts.deleteSuccessful;

export const getTraffic = state => state.admin.traffic.traffic;
export const getIsLoadingTraffic = state => state.admin.traffic.isLoading;
export const getTrafficError = state => state.admin.traffic.errorMessage;
export const getTrafficUpsertError = state => state.admin.traffic.upsertError;
export const getTrafficUpsertSuccess = state =>
  state.admin.traffic.upsertSuccessful;

import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';

import jwtDecode from 'jwt-decode';

export const defaultAbility = new Ability();

export const updateAbility = async (ability, user) => {
  const { abilities } = user;

  if (!abilities) return;

  const { rules } = jwtDecode(abilities);

  const unpackedRules = unpackRules(rules);

  ability.update(unpackedRules);
};

import { createSlice } from '@reduxjs/toolkit';
import { fetchUser, updateUser } from './asyncAction';

import { STATUS_ERROR } from '@/utils/constants/errors';

const initialState = {
  user: null,
  isLoading: false,
  errorMessage: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser() {
      return initialState;
    },
  },
  extraReducers: ({ addCase }) => {
    // Fetch user
    addCase(fetchUser.pending, state => {
      state.errorMessage = '';
      state.isLoading = true;
    });
    addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    });
    addCase(fetchUser.rejected, (state, { error, payload }) => {
      state.errorMessage =
        payload === STATUS_ERROR.notFound ? payload : error.message;
      state.isLoading = false;
    });

    // Update User
    addCase(updateUser.pending, state => {
      state.errorMessage = '';
      state.isLoading = true;
    });
    addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    });
    addCase(updateUser.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;

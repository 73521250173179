export const pages = {
  rootPath: '/',
  registration: '/registration',
  dashboard: '/dashboard',
  admin: '/admin',
  artistsCreation: '/projects/create',
  artistsOverview: '/projects/overview',
  artistsDashboard: id => `/projects/${id}`,
  artistsEdit: id => `/projects/${id}/edit`,
  artistsManage: id => `/projects/${id}/manage`,
  managerSettings: '/manager/settings',
  changePlan: '/manager/changePlan',
  logout: '/auth/sign-out',
  signup: '/signup',

  // Error pages
  notFound: '/404',
};

const pagesRegex = {
  // Authenticated pages
  rootPath: /^\/$/,
  registration: /\/registration/,
  dashboard: /\/dashboard/,
  admin: /\/admin/,
  artistsCreation: /\/projects\/create/,
  artistsOverview: /\/projects\/overview/,
  artistsDashboard: /\/projects\/\d+/,
  managerSettings: /\/manager\/settings/,
  changePlan: /\/manager\/changePlan/,
  logout: /\/auth\/sign-out/,

  // Unauthenticated pages
  signup: /\/signup/,

  // Error pages
  notFound: /\/404/,
};
const { signup, notFound, ...rest } = pagesRegex;

export const unauthenticatedRoutesRegex = [signup, notFound];
export const authenticatedRoutesRegex = Object.values(rest);

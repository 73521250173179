import MainLayout from '@/layouts/MainLayout';
import { Flex, Spinner } from '@chakra-ui/react';
import { useAuthorization } from './use-authorization';

export function Authorized({ children }) {
  const { status } = useAuthorization();

  if (status === 'loading') {
    return (
      <MainLayout>
        <Flex w='full' h='full' alignItems='center' justifyContent='center'>
          <Spinner color='alpha.500' size='lg' />
        </Flex>
      </MainLayout>
    );
  }

  return children;
}

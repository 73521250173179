var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"jlsoFYO2JfEyRfyJd0ApV"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = isNaN(process.env.SENTRY_SAMPLE_RATE)
  ? 0
  : Number(process.env.SENTRY_SAMPLE_RATE);

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: samplingContext => {
    const isHealthCheckUrl = /healthcheck/.test(
      samplingContext?.transactionContext?.name,
    );

    if (isHealthCheckUrl) {
      return 0;
    }

    return SAMPLE_RATE;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  debug: false,

  normalizeDepth: 10,
});

const Heading = {
  baseStyle: {
    lineHeight: 1.5,
  },
  sizes: {
    '6xl': {
      fontSize: '6xl',
      fontWeight: 'bold',
    },
    '5xl': {
      fontSize: '5xl',
      fontWeight: 'bold',
    },
    '4xl': {
      fontSize: '4xl',
      fontWeight: 'extrabold',
    },
    '3xl': {
      fontSize: '3xl',
      fontWeight: 'bold',
    },
    '2xl': {
      fontSize: '2xl',
      fontWeight: 'bold',
    },
    xl: {
      fontSize: 'xl',
      fontWeight: 'extrabold',
    },
    lg: {
      fontSize: 'lg',
      fontWeight: 'bold',
    },
    md: { fontSize: 'md', fontWeight: 'extrabold' },
    sm: { fontSize: 'sm', fontWeight: 'bold' },
    xs: { fontSize: 'xs', fontWeight: 'bold' },
  },
};

export default Heading;

import React, { useEffect, useContext } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useUserProfile } from '../hooks/requests/users/useUserData';

import { login } from './helpers';
import { authenticatedRoutesRegex, pages } from '@/utils/pages';
import { AbilityContext, updateAbility } from '@/utils/casl';

export const useAuthorization = () => {
  const router = useRouter();
  const ability = useContext(AbilityContext);

  // only force authentication on routes that require that
  const requiresAuthentication = !!authenticatedRoutesRegex.find(routeRegex =>
    router.asPath.match(routeRegex),
  );

  const { data: session, status } = useSession({
    required: requiresAuthentication,
    onUnauthenticated: () => {
      login(router.asPath === pages.logout ? pages.rootPath : router.asPath);
    },
  });

  useEffect(() => {
    if (session?.user?.abilities) {
      updateAbility(ability, session?.user);
    }
  }, [session?.user, ability]);

  const profile = useUserProfile(session?.user.id);

  return { session, status, profile };
};

import Client from '@/lib/axios';

const axios = Client();

export const getEvents = async ({ page, pageSize, upcoming }) =>
  axios.get(`/events`, { params: { page, pageSize, upcoming } });

export const upsertEvent = async event => axios.post(`/events`, event);

export const deleteEvent = async eventId => axios.delete(`/events/${eventId}`);

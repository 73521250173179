import { createSlice } from '@reduxjs/toolkit';
import { fetchSocialMedia } from './asyncAction';

const initialState = {
  isLoading: false,
  socialMedia: null,
  errorMessage: null,
};

const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(fetchSocialMedia.pending, state => {
      state.errorMessage = null;
      state.isLoading = true;
    });
    addCase(fetchSocialMedia.fulfilled, (state, { payload }) => {
      state.errorMessage = null;
      state.socialMedia = payload;
      state.isLoading = false;
    });
    addCase(fetchSocialMedia.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export default socialMediaSlice.reducer;

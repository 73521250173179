import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { getArtistCategories } from '@/api/__mocks__/artists';
import { FILES_FORMAT } from '@/utils/constants/api';
import { ARTIST_POSTS_CATEGORIES } from '@/utils/constants/artists';
import { STATUS_ERROR } from '@/utils/constants/errors';
import { saveFile } from '@/utils/saveFile';

import {
  getArtists,
  getArtist,
  patchArtist,
  getArtistDashboard,
  getArtistComparison,
  postArtistToCompare,
  deleteArtistToCompare,
  getArtistPosts,
  getTrafficStatistics,
  getExportNDS,
  getExportNDSStatistics,
} from '@/api/artists';

import { getSocialMedia } from '@/api/social';

export const fetchArtist = createAsyncThunk(
  'artists/fetchArtist',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getArtist(id);

      return data;
    } catch (err) {
      return err.status === STATUS_ERROR.notFound
        ? rejectWithValue(err.status)
        : rejectWithValue(err);
    }
  },
);

export const updateArtist = createAsyncThunk(
  'artists/updateArtist',
  async ({ id, artist }) => {
    const { data } = await patchArtist({ id, artist });

    return data;
  },
);

export const fetchArtistCategories = createAsyncThunk(
  'artists/fetchArtistCategories',
  async () => {
    const { data } = await getArtistCategories();

    return data;
  },
);

export const fetchManagedArtists = createAsyncThunk(
  'artists/fetchManagedArtists',
  async ({ search, getAll, categories }) => {
    const { data } = await getArtists({ search, getAll, categories });

    return data;
  },
);

export const fetchArtistDashboard = createAsyncThunk(
  'artists/fetchArtistDashboard',
  async ({ artistId, socialId, startDate, endDate, dateRange }) => {
    const { data } = await getArtistDashboard({
      artistId,
      socialId,
      startDate,
      endDate,
      dateRange,
    });

    return data;
  },
);

export const fetchArtistComparison = createAsyncThunk(
  'artists/fetchArtistComparison',
  async artistId => {
    const { data } = await getArtistComparison(artistId);

    return data;
  },
);

export const addArtistToCompare = createAsyncThunk(
  'artists/addArtistComparison',
  async ({ artistId, comparisonArtist }) => {
    await postArtistToCompare({ artistId, comparisonArtist });

    const { data } = await getArtistComparison(artistId);

    return data;
  },
);

export const removeArtistToCompare = createAsyncThunk(
  'artists/removeArtistToCompare',
  async ({ artistId, comparisonId }) => {
    await deleteArtistToCompare({ artistId, comparisonId });

    const { data } = await getArtistComparison(artistId);

    return data;
  },
);

export const fetchLatestPosts = createAsyncThunk(
  'artists/fetchLatestPosts',
  async ({ artistId, socialId, startDate, endDate }) => {
    const mostEngaged = getArtistPosts({
      artistId,
      socialId,
      category: ARTIST_POSTS_CATEGORIES.mostEngaged,
      latest: 1,
      startDate,
      endDate,
    });

    const mostLiked = getArtistPosts({
      artistId,
      socialId,
      category: ARTIST_POSTS_CATEGORIES.mostLiked,
      latest: 1,
      startDate,
      endDate,
    });

    const [{ data: mostEngagedPost }, { data: mostLikedPost }] =
      await Promise.all([mostEngaged, mostLiked]);

    const response = {
      mostEngagedPost: mostEngagedPost || null,
      mostLikedPost: mostLikedPost || null,
    };

    return response;
  },
);

export const fetchTrafficStats = createAsyncThunk(
  'artists/fetchTrafficStats',
  async ({ artistId, socialId, endDate }) => {
    const { data } = await getTrafficStatistics({
      artistId,
      socialId,
      endDate,
    });

    return data;
  },
);

export const exportNDSData = createAsyncThunk(
  'artists/exportNDSData',
  async ({ artistId, artistName, socialId, startDate, endDate, dateRange }) => {
    const { data } = await getExportNDS({
      artistId,
      socialId,
      startDate,
      endDate,
      dateRange,
    });

    const name = artistName.split(' ').join('_');
    const from = format(startDate, 'yyyy-MM-dd');
    const to = format(endDate, 'yyyy-MM-dd');

    const blob = new Blob([data], { type: FILES_FORMAT.CSV });
    const file = `${name}_${from}-to-${to}${FILES_FORMAT.CSV}`;

    saveFile({ blob, as: file });
  },
);

export const exportNDSStatistics = createAsyncThunk(
  'artists/exportNDSStatistics',
  async ({ artistId, artistName, socialId, month, year }) => {
    const { data } = await getExportNDSStatistics({
      artistId,
      socialId,
      month,
      year,
    });

    const { data: socialsData } = await getSocialMedia();

    const name = artistName.split(' ').join('_');
    const { name: socialName } = socialsData.socials.find(
      ({ id }) => id === socialId,
    );
    const blob = new Blob([data], { type: FILES_FORMAT.CSV });
    const file = `${name}_${socialName}_${month}-${year}${FILES_FORMAT.CSV}`;

    saveFile({ blob, as: file });
  },
);

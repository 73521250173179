import Client from '@/lib/axios';

const axios = Client();

export const getArtists = ({
  search,
  showEggArtistsOnly = 0,
  getAll = 0,
  categories,
}) =>
  axios.get('/artists', {
    params: {
      ...(search && { search }),
      showEggArtistsOnly,
      getAll,
      categories,
    },
  });

export const getArtist = async id => axios.get(`/artists/${id}`);

export const addArtist = async artist => axios.post(`/artists`, artist);

export const patchArtist = async ({ id, artist }) =>
  axios.patch(`/artists/${id}`, artist);

export const getArtistStatistics = async ({
  artistId,
  month,
  year,
  socialId,
}) =>
  axios.get(`/artists/${artistId}/statistics`, {
    params: { month, year, socialId },
  });

export const postArtistStatistics = async ({
  artistId,
  socialId,
  statistics,
}) =>
  axios.post(`/artists/${artistId}/statistics`, statistics, {
    params: { socialId },
  });

export const deleteArtistStatistics = async ({ artistId, statisticIds }) =>
  axios.delete(`/artists/${artistId}/statistics`, { data: statisticIds });

export const getArtistDashboard = async ({
  artistId,
  socialId,
  startDate,
  endDate,
  dateRange,
}) =>
  axios.get(`/artists/${artistId}/dashboard`, {
    params: { socialId, startDate, endDate, dateRange },
  });

export const getArtistComparison = async artistId =>
  axios.get(`/artists/${artistId}/comparison`);

export const postArtistToCompare = async ({ artistId, comparisonArtist }) =>
  axios.post(`/artists/${artistId}/comparison`, comparisonArtist);

export const deleteArtistToCompare = async ({ artistId, comparisonId }) =>
  axios.delete(`/artists/${artistId}/comparison`, {
    data: { id: comparisonId },
  });

export const getArtistPosts = async ({
  artistId,
  socialId,
  category,
  latest = 0,
  startDate,
  endDate,
}) =>
  axios.get(`/artists/${artistId}/posts`, {
    params: { socialId, category, latest, startDate, endDate },
  });

export const upsertArtistPosts = async ({ artistId, post }) =>
  axios.post(`/artists/${artistId}/posts`, post);

export const deleteArtistPosts = async ({ artistId, postId }) =>
  axios.delete(`/artists/${artistId}/posts/${postId}`);

export const getTraffic = async ({ artistId, month, year, socialId }) =>
  axios.get(`/artists/${artistId}/traffic`, {
    params: { month, year, socialId },
  });

export const postTraffic = async ({ artistId, socialId, traffic }) =>
  axios.post(`/artists/${artistId}/traffic`, traffic, {
    params: { socialId },
  });

export const deleteTraffic = async ({ artistId, trafficIds }) =>
  axios.delete(`/artists/${artistId}/traffic`, { data: trafficIds });

export const getTrafficStatistics = async ({ artistId, socialId, endDate }) =>
  axios.get(`/artists/${artistId}/dashboard-traffic`, {
    params: { socialId, endDate },
  });

export const getExportNDS = async ({
  artistId,
  socialId,
  startDate,
  endDate,
  dateRange,
}) =>
  axios.get(`/artists/${artistId}/export`, {
    params: { socialId, startDate, endDate, dateRange },
  });

export const getExportNDSStatistics = async ({
  artistId,
  socialId,
  month,
  year,
}) =>
  axios.get(`/artists/${artistId}/export-statistics`, {
    params: { socialId, month, year },
  });

export const deleteProject = id => axios.delete(`/artists/${id}`);

import {
  format,
  parseISO,
  formatDistanceToNowStrict,
  sub,
  getWeek,
  getYear,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachQuarterOfInterval,
  lastDayOfMonth,
  lastDayOfWeek,
  lastDayOfYear,
  endOfQuarter,
  fromUnixTime,
} from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_TIME_FORMAT = 'hh:mm:ss';
export const WEEK_DAYS = 7;
export const WEEK_OPTIONS = { weekStartsOn: 1 };
export const BILLING_PERIOD_FORMAT = 'MMMM d, u';

const EMPTY_DATETIME = '-';

/**
 * Format a date with the given format string
 * Patterns accepted: https://date-fns.org/v2.27.0/docs/format
 *
 * @param {string} dateString ISO string that contains the date to format
 * @param {string} dateFormat string with the pattern for formatting the date
 *
 * @returns {string} the date given formatted with the given format pattern.
 */
export const formatDateTime = (
  dateString,
  dateFormat = DEFAULT_DATE_FORMAT,
) => {
  if (!dateString) {
    return EMPTY_DATETIME;
  }

  const parsedDate = parseISO(dateString);

  if (isNaN(parsedDate)) {
    return EMPTY_DATETIME;
  }

  return format(parsedDate, dateFormat);
};

/**
 *
 * @param {string} dateString  ISO string for the date we need to know the difference of
 * @returns {string} a formatted string with the date difference or an empty value
 */
export const formatRelativeDate = dateString => {
  if (!dateString) {
    return EMPTY_DATETIME;
  }

  const parsedDate = parseISO(dateString);

  if (isNaN(parsedDate)) {
    return EMPTY_DATETIME;
  }

  return formatDistanceToNowStrict(parsedDate, { addSuffix: true });
};

export const getMonthsInAYear = () => {
  const today = new Date();

  return eachMonthOfInterval({
    start: sub(today, { years: 1 }),
    end: today,
  });
};

export const getDateWithoutOffset = date => {
  const dt = new Date(date);

  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
};

export const formatWithoutOffset = (date, dateFormat = DEFAULT_DATE_FORMAT) =>
  format(getDateWithoutOffset(date), dateFormat);

export const getWeeksInMonthByDate = date => {
  const monthStart = parseISO(date);

  return eachWeekOfInterval(
    { start: monthStart, end: lastDayOfMonth(monthStart) },
    WEEK_OPTIONS,
  ).map(date => ({
    startDate: format(new Date(date), 'yyyy-MM-dd'),
    endDate: format(new Date(lastDayOfWeek(date, WEEK_OPTIONS)), 'yyyy-MM-dd'),
    week: getWeek(date),
  }));
};

export const getYearQuarters = year =>
  eachQuarterOfInterval({
    start: new Date(getYear(year), 0, 1),
    end: lastDayOfYear(year),
  }).map(q => ({
    from: q,
    to: endOfQuarter(q),
  }));

/**
 * Format a date with the given format string
 * Patterns accepted: https://date-fns.org/v2.27.0/docs/format
 *
 * @param {number} dateNumber UnixTime number that contains the date to format
 * @param {string} dateFormat string with the pattern for formatting the date
 *
 * @returns {string} the date given formatted with the given format pattern.
 */
export const formatBillingDate = (
  dateNumber,
  dateFormat = DEFAULT_DATE_FORMAT,
) => {
  if (!dateNumber) {
    return EMPTY_DATETIME;
  }
  const formattedDate = fromUnixTime(dateNumber);

  return format(formattedDate, dateFormat);
};

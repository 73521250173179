const baseStyle = {
  fontSize: "md",
  fontWeight: 'normal',
  lineHeight: '110%',
};

const variantDefault = {
  bg: 'gray.700',
  color:'white',
};

const variantWarning = {
  bg: 'zeta.300',
  color:'white',
};

const variantDanger = {
  bg: 'epsilon.500',
  color:'white',
};

const variants = {
  default: variantDefault,
  warning: variantWarning,
  danger: variantDanger,
};

const defaultProps = {
  variant: "default",
};

const TooltipStyles = {
  baseStyle,
  variants,
  defaultProps,
};

export default TooltipStyles;

export const getArtist = state => state.artists.artist;
export const getIsLoading = state => state.artists.isLoading;
export const getErrorMessage = state => state.artists.errorMessage;
export const getArtistUpdated = state => state.artists.artistUpdated;

export const getIsLoadingArtistCategories = state =>
  state.artists.categories.isLoading;
export const getArtistCategories = state => state.artists.categories.values;
export const getErrorArtistCategories = state =>
  state.artists.categories.errorMessage;

export const getIsLoadingManagedArtists = state =>
  state.artists.managedArtists.isLoading;
export const getManagedArtists = state => state.artists.managedArtists.artists;
export const getErrorManagedArtists = state =>
  state.artists.managedArtists.errorMessage;

export const getArtistDashboard = state => state.artists.dashboard.statistics;
export const getIsLoadingDashboard = state => state.artists.dashboard.isLoading;
export const getErrorDashboard = state => state.artists.dashboard.errorMessage;

export const getArtistComparison = state => state.artists.comparison.artists;
export const getIsLoadingArtistComparison = state =>
  state.artists.comparison.isLoading;
export const getErrorArtistComparison = state =>
  state.artists.comparison.errorMessage;
export const getArtistComparisonAdded = state =>
  state.artists.comparison.successfullyAdded;
export const getArtistComparisonDeleted = state =>
  state.artists.comparison.successfullyDeleted;

export const getLatestPosts = state => state.artists.posts.posts;
export const getIsLoadingLatestPosts = state => state.artists.posts.isLoading;
export const getErrorLatestPosts = state => state.artists.posts.errorMessage;

export const getTraffic = state => state.artists.traffic.traffic;
export const getIsLoadingTraffic = state => state.artists.traffic.isLoading;
export const getTrafficError = state => state.artists.traffic.errorMessage;

export const getExported = state => state.artists.export.exported;
export const getIsLoadingExport = state => state.artists.export.isLoading;
export const getExportError = state => state.artists.export.errorMessage;

import { extendTheme } from '@chakra-ui/react';

import colors from './theme/colors';
import typography from './theme/typography';
import heading from './theme/heading';
import button from './theme/button';
import badge from './theme/badge';
import input from './theme/input';
import tag from './theme/tag';
import tooltip from './theme/tooltip';
import tab from './theme/tab';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'DMSans',
      },
      'html, body, #__next': {
        height: '100%',
        backgroundColor: 'gray.50',
      },
    },
  },
  components: {
    Button: button,
    Badge: badge,
    Input: input,
    Tag: tag,
    Tooltip: tooltip,
    Heading: heading,
    Tabs: tab,
  },
  colors,
  ...typography,
});

export default theme;

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchArtistCategories,
  fetchManagedArtists,
  fetchArtist,
  updateArtist,
  fetchArtistDashboard,
  fetchArtistComparison,
  addArtistToCompare,
  removeArtistToCompare,
  fetchLatestPosts,
  fetchTrafficStats,
  exportNDSData,
  exportNDSStatistics,
} from './asyncAction';

import { STATUS_ERROR } from '@/utils/constants/errors';

const initialState = {
  artist: null,
  isLoading: false,
  errorMessage: null,
  artistUpdated: false,

  categories: {
    isLoading: false,
    values: null,
    errorMessage: null,
  },
  managedArtists: {
    isLoading: false,
    artists: null,
    errorMessage: null,
  },
  dashboard: {
    statistics: null,
    isLoading: false,
    errorMessage: null,
  },
  comparison: {
    artists: null,
    isLoading: false,
    errorMessage: null,
    successfullyAdded: false,
    successfullyDeleted: false,
  },
  posts: {
    posts: null,
    isLoading: false,
    errorMessage: null,
  },
  traffic: {
    traffic: null,
    isLoading: false,
    errorMessage: null,
  },
  export: { exported: false, isLoading: false, errorMessage: null },
};

const artistsSlice = createSlice({
  name: 'artists',
  initialState,
  reducers: {
    clearArtist(state) {
      state.artist = null;
      state.isLoading = false;
      state.errorMessage = null;
      state.artistUpdated = false;
    },
    clearArtistDashboard(state) {
      state.dashboard.statistics = null;
      state.dashboard.isLoading = false;
      state.dashboard.errorMessage = null;
    },
    clearManagedArtists(state) {
      state.managedArtists.artists = null;
      state.managedArtists.isLoading = false;
      state.managedArtists.errorMessage = null;
    },
    clearComparison(state) {
      state.comparison.artists = null;
      state.comparison.errorMessage = null;
      state.comparison.isLoading = false;
      state.comparison.successfullyAdded = false;
      state.comparison.successfullyDeleted = false;
    },
    clearExport(state) {
      state.export.exported = false;
      state.export.isLoading = false;
      state.export.errorMessage = null;
    },
  },
  extraReducers: ({ addCase }) => {
    // fetch artist
    addCase(fetchArtist.pending, state => {
      state.artist = null;
      state.errorMessage = null;
      state.isLoading = true;
    });
    addCase(fetchArtist.fulfilled, (state, { payload }) => {
      state.artist = payload;
      state.isLoading = false;
    });
    addCase(fetchArtist.rejected, (state, { error, payload }) => {
      state.errorMessage =
        payload === STATUS_ERROR.notFound ? payload : error.message;
      state.isLoading = false;
    });

    // Update artist
    addCase(updateArtist.pending, state => {
      state.artist = null;
      state.errorMessage = null;
      state.isLoading = true;
    });
    addCase(updateArtist.fulfilled, (state, { payload }) => {
      state.artist = payload;
      state.artistUpdated = true;
      state.isLoading = false;
    });
    addCase(updateArtist.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });

    // fetch categories
    addCase(fetchArtistCategories.pending, state => {
      state.categories.errorMessage = null;
      state.categories.isLoading = true;
    });
    addCase(fetchArtistCategories.fulfilled, (state, { payload }) => {
      state.categories.errorMessage = null;
      state.categories.values = payload;
      state.categories.isLoading = false;
    });
    addCase(fetchArtistCategories.rejected, (state, { error }) => {
      state.categories.errorMessage = error?.message ?? 'unknown error';
      state.categories.isLoading = false;
    });

    // fetch managed artists
    addCase(fetchManagedArtists.pending, state => {
      state.managedArtists.errorMessage = null;
      state.managedArtists.isLoading = true;
    });
    addCase(fetchManagedArtists.fulfilled, (state, { payload }) => {
      state.managedArtists.errorMessage = null;
      state.managedArtists.artists = payload;
      state.managedArtists.isLoading = false;
    });
    addCase(fetchManagedArtists.rejected, (state, { error }) => {
      state.managedArtists.errorMessage = error?.message ?? 'unknown error';
      state.managedArtists.isLoading = false;
    });

    // fetch artists dashboard data
    addCase(fetchArtistDashboard.pending, state => {
      state.dashboard.isLoading = true;
      state.dashboard.errorMessage = null;
    });
    addCase(fetchArtistDashboard.fulfilled, (state, { payload }) => {
      state.dashboard.statistics = payload;
      state.dashboard.isLoading = false;
      state.dashboard.errorMessage = null;
    });
    addCase(fetchArtistDashboard.rejected, (state, { error }) => {
      state.dashboard.errorMessage = error?.message ?? 'unknown error';
      state.dashboard.isLoading = false;
    });
    // fetch artists to compare
    addCase(fetchArtistComparison.pending, state => {
      state.comparison.artists = null;
      state.comparison.errorMessage = null;
      state.comparison.isLoading = true;
    });
    addCase(fetchArtistComparison.fulfilled, (state, { payload }) => {
      state.comparison.errorMessage = null;
      state.comparison.artists = payload;
      state.comparison.isLoading = false;
    });
    addCase(fetchArtistComparison.rejected, (state, { error }) => {
      state.comparison.errorMessage = error?.message ?? 'unknown error';
      state.comparison.isLoading = false;
    });
    // Add/change artist to compare
    addCase(addArtistToCompare.pending, state => {
      state.comparison.artists = null;
      state.comparison.errorMessage = null;
      state.comparison.isLoading = true;
      state.comparison.successfullyAdded = false;
      state.comparison.successfullyDeleted = false;
    });
    addCase(addArtistToCompare.fulfilled, (state, { payload }) => {
      state.comparison.artists = payload;
      state.comparison.isLoading = false;
      state.comparison.successfullyAdded = true;
    });
    addCase(addArtistToCompare.rejected, (state, { error }) => {
      state.comparison.errorMessage = error?.message ?? 'unknown error';
      state.comparison.isLoading = false;
    });
    // Remove artist to compare
    addCase(removeArtistToCompare.pending, state => {
      state.comparison.artists = null;
      state.comparison.errorMessage = null;
      state.comparison.isLoading = true;
      state.comparison.successfullyAdded = false;
      state.comparison.successfullyDeleted = false;
    });
    addCase(removeArtistToCompare.fulfilled, (state, { payload }) => {
      state.comparison.artists = payload;
      state.comparison.isLoading = false;
      state.comparison.successfullyDeleted = true;
    });
    addCase(removeArtistToCompare.rejected, (state, { error }) => {
      state.comparison.errorMessage = error?.message ?? 'unknown error';
      state.comparison.isLoading = false;
    });
    // Get latest posts
    addCase(fetchLatestPosts.pending, state => {
      state.posts.posts = null;
      state.posts.isLoading = true;
      state.posts.errorMessage = null;
    });
    addCase(fetchLatestPosts.fulfilled, (state, { payload }) => {
      state.posts.posts = payload;
      state.posts.isLoading = false;
    });
    addCase(fetchLatestPosts.rejected, (state, { error }) => {
      state.posts.errorMessage = error?.message ?? 'unknown error';
      state.posts.isLoading = false;
    });
    // Get traffic statistics
    addCase(fetchTrafficStats.pending, state => {
      state.traffic.traffic = null;
      state.traffic.isLoading = true;
      state.traffic.errorMessage = null;
    });
    addCase(fetchTrafficStats.fulfilled, (state, { payload }) => {
      state.traffic.traffic = payload;
      state.traffic.isLoading = false;
    });
    addCase(fetchTrafficStats.rejected, (state, { error }) => {
      state.traffic.errorMessage = error?.message ?? 'unknown error';
      state.traffic.isLoading = false;
    });
    // Export statistics data (dashboard)
    addCase(exportNDSData.pending, state => {
      state.export.exported = false;
      state.export.isLoading = true;
      state.export.errorMessage = null;
    });
    addCase(exportNDSData.fulfilled, state => {
      state.export.exported = true;
      state.export.isLoading = false;
    });
    addCase(exportNDSData.rejected, (state, { error }) => {
      state.export.errorMessage = error?.message ?? 'unknown error';
      state.export.isLoading = false;
    });
    // Export NDS statistics data (admin/manage)
    addCase(exportNDSStatistics.pending, state => {
      state.export.exported = false;
      state.export.isLoading = true;
      state.export.errorMessage = null;
    });
    addCase(exportNDSStatistics.fulfilled, state => {
      state.export.exported = true;
      state.export.isLoading = false;
    });
    addCase(exportNDSStatistics.rejected, (state, { error }) => {
      state.export.errorMessage = error?.message ?? 'unknown error';
      state.export.isLoading = false;
    });
  },
});

export const {
  clearArtist,
  clearArtistDashboard,
  clearManagedArtists,
  clearComparison,
  clearExport,
} = artistsSlice.actions;

export default artistsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { PROVINCES } from '@/utils/constants/artists';

import {
  fetchArtists,
  fetchArtistStatistics,
  uploadArtistStatistics,
  removeArtistStatistics,
  fetchArtistPosts,
  uploadArtistPosts,
  removeArtistPosts,
  fetchTraffic,
  uploadTraffic,
  removeTraffic,
} from './asyncAction';

const initialState = {
  artists: null,
  isLoading: false,
  errorMessage: null,

  statistics: {
    statistics: null,
    isLoading: false,
    errorMessage: null,
    upsertError: null,
    upsertSuccessful: false,
  },

  posts: {
    posts: null,
    isLoading: false,
    errorMessage: null,
    upsertError: null,
    upsertSuccessful: false,
    deleteSuccessful: false,
  },

  traffic: {
    traffic: null,
    isLoading: false,
    errorMessage: null,
    upsertError: null,
    upsertSuccessful: false,
  },
};

const mapProvincesData = traffic =>
  traffic?.map(data => ({
    ...data,
    ...PROVINCES.reduce((acc, el) => ({ ...acc, [el]: +data[el] }), {}),
  }));

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearStatistics(state) {
      state.statistics.statistics = null;
      state.statistics.isLoading = false;
      state.statistics.errorMessage = null;
      state.statistics.upsertError = false;
      state.statistics.upsertSuccessful = false;
    },
    clearPosts(state) {
      state.posts.posts = null;
      state.posts.isLoading = false;
      state.posts.errorMessage = null;
      state.posts.upsertSuccessful = false;
      state.posts.upsertError = null;
      state.posts.deleteSuccessful = false;
    },
    clearTraffic(state) {
      state.traffic.traffic = null;
      state.traffic.isLoading = false;
      state.traffic.errorMessage = null;
      state.traffic.upsertError = false;
      state.traffic.upsertSuccessful = false;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchArtists.pending, state => {
      state.isLoading = true;
      state.errorMessage = null;
    });
    addCase(fetchArtists.fulfilled, (state, { payload }) => {
      state.artists = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    addCase(fetchArtists.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
    // Get artist statistics
    addCase(fetchArtistStatistics.pending, state => {
      state.statistics.isLoading = true;
      state.statistics.errorMessage = null;
      state.statistics.upsertError = null;
      state.statistics.upsertSuccessful = false;
    });
    addCase(fetchArtistStatistics.fulfilled, (state, { payload }) => {
      state.statistics.statistics = payload?.statistics;
      state.statistics.isLoading = false;
      state.statistics.errorMessage = null;
    });
    addCase(fetchArtistStatistics.rejected, (state, { error }) => {
      state.statistics.errorMessage = error?.message ?? 'unknown error';
      state.statistics.isLoading = false;
    });
    // Upsert artist statistics
    addCase(uploadArtistStatistics.pending, state => {
      state.statistics.statistics = null;
      state.statistics.isLoading = true;
      state.statistics.upsertError = null;
      state.statistics.upsertSuccessful = false;
    });
    addCase(uploadArtistStatistics.fulfilled, (state, { payload }) => {
      state.statistics.statistics = payload?.statistics;
      state.statistics.isLoading = false;
      state.statistics.upsertSuccessful = true;
    });
    addCase(uploadArtistStatistics.rejected, (state, { error }) => {
      state.statistics.upsertError = error?.message ?? 'unknown error';
      state.statistics.isLoading = false;
    });
    // Remove artist statistics
    addCase(removeArtistStatistics.pending, state => {
      state.statistics.statistics = null;
      state.statistics.isLoading = true;
      state.statistics.upsertError = null;
    });
    addCase(removeArtistStatistics.fulfilled, (state, { payload }) => {
      state.statistics.statistics = payload?.statistics;
      state.statistics.isLoading = false;
      state.statistics.upsertError = null;
    });
    addCase(removeArtistStatistics.rejected, (state, { error }) => {
      state.statistics.upsertError = error?.message ?? 'unknown error';
      state.statistics.isLoading = false;
    });
    // Get artist posts
    addCase(fetchArtistPosts.pending, state => {
      state.posts.isLoading = true;
      state.posts.errorMessage = null;
      state.posts.upsertError = null;
      state.posts.upsertSuccessful = false;
    });
    addCase(fetchArtistPosts.fulfilled, (state, { payload }) => {
      state.posts.posts = payload?.posts;
      state.posts.isLoading = false;
      state.posts.errorMessage = null;
    });
    addCase(fetchArtistPosts.rejected, (state, { error }) => {
      state.posts.errorMessage = error?.message ?? 'unknown error';
      state.posts.isLoading = false;
    });
    // Upsert artist posts
    addCase(uploadArtistPosts.pending, state => {
      state.posts.isLoading = true;
      state.posts.upsertError = null;
      state.posts.upsertSuccessful = false;
    });
    addCase(uploadArtistPosts.fulfilled, (state, { payload }) => {
      state.posts.posts = payload?.posts;
      state.posts.isLoading = false;
      state.posts.upsertError = null;
      state.posts.upsertSuccessful = true;
    });
    addCase(uploadArtistPosts.rejected, (state, { error }) => {
      state.posts.upsertError = error?.message ?? 'unknown error';
      state.posts.isLoading = false;
      state.posts.upsertSuccessful = false;
    });
    // Delete artist posts
    addCase(removeArtistPosts.pending, state => {
      state.posts.isLoading = true;
      state.posts.errorMessage = null;
      state.posts.deleteSuccessful = false;
      state.posts.upsertSuccessful = false;
    });
    addCase(removeArtistPosts.fulfilled, (state, { payload }) => {
      state.posts.posts = payload?.posts;
      state.posts.isLoading = false;
      state.posts.deleteSuccessful = true;
    });
    addCase(removeArtistPosts.rejected, (state, { error }) => {
      state.posts.errorMessage = error?.message ?? 'unknown error';
      state.posts.isLoading = false;
    });
    // Get artist traffic
    addCase(fetchTraffic.pending, state => {
      state.traffic.traffic = null;
      state.traffic.isLoading = true;
      state.traffic.errorMessage = null;
      state.traffic.upsertError = null;
      state.traffic.upsertSuccessful = false;
    });
    addCase(fetchTraffic.fulfilled, (state, { payload }) => {
      state.traffic.traffic = mapProvincesData(payload.traffic);
      state.traffic.isLoading = false;
    });
    addCase(fetchTraffic.rejected, (state, { error }) => {
      state.traffic.errorMessage = error?.message ?? 'unknown error';
      state.traffic.isLoading = false;
    });
    // Upsert artist traffic
    addCase(uploadTraffic.pending, state => {
      state.traffic.traffic = null;
      state.traffic.isLoading = true;
      state.statistics.upsertError = null;
      state.traffic.upsertSuccessful = false;
    });
    addCase(uploadTraffic.fulfilled, (state, { payload }) => {
      state.traffic.traffic = mapProvincesData(payload.traffic);
      state.traffic.isLoading = false;
      state.traffic.upsertSuccessful = true;
    });
    addCase(uploadTraffic.rejected, (state, { error }) => {
      state.traffic.upsertError = error?.message ?? 'unknown error';
      state.traffic.isLoading = false;
    });
    // Remove artist traffic
    addCase(removeTraffic.pending, state => {
      state.traffic.traffic = null;
      state.traffic.isLoading = true;
      state.traffic.upsertError = null;
    });
    addCase(removeTraffic.fulfilled, (state, { payload }) => {
      state.traffic.traffic = mapProvincesData(payload.traffic);
      state.traffic.isLoading = false;
    });
    addCase(removeTraffic.rejected, (state, { error }) => {
      state.traffic.upsertError = error?.message ?? 'unknown error';
      state.traffic.isLoading = false;
    });
  },
});

export const { clearStatistics, clearPosts, clearTraffic } = adminSlice.actions;

export default adminSlice.reducer;

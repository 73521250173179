import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const REFRESH_SESSION_IN_SECONDS = 15 * 60; // Refresh session every 15 minutes
export const REFRESH_TOKEN_ERROR = 'RefreshAccessTokenError';
export const MAX_IDLE_SESSION_TIME = 30 * 24 * 60 * 60; // 30 days'
export const AUTH_PROVIDER = 'auth0';

// There's a bug where the session cookie is not completely cleaned so
// auth0's logout must also be implicitly called to invalidate the session:
// See https://github.com/nextauthjs/next-auth/issues/836#issuecomment-1007630849
export const AUTH_LOGOUT_URL = `${
  publicRuntimeConfig.AUTH0_ISSUER
}/v2/logout?client_id=${
  publicRuntimeConfig.AUTH0_CLIENT_ID
}&returnTo=`;

// Constant that reflects the status of the auth process that next-auth sets
export const authStatus = {
  LOADING: 'loading',
  AUTHENTICATED: 'authenticated',
  AUTHENTICATION_FAILED: 'unauthenticated',
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from '@/utils/constants/user';

import { getUsers } from '@/api/user';
import { patchClientStatus } from '@/api/clients';

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({ search }) => {
    const { data } = await getUsers({
      search,
      role: USER_ROLES.artistManager,
    });

    return data;
  },
);

export const updateClientStatus = createAsyncThunk(
  'clients/updateClientStatus',
  async ({ id, status }) => {
    await patchClientStatus({ id, status });

    const { data } = await getUsers({ role: USER_ROLES.artistManager });

    return data;
  },
);

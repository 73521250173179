import { createSlice } from '@reduxjs/toolkit';
import { fetchEvents, uploadEvent, removeEvent } from './asyncAction';

const initialState = {
  events: null,
  isLoading: false,
  errorMessage: '',
  upsertError: '',
  upsertSuccessful: false,
  deleteSuccessful: false,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearEvents() {
      return initialState;
    },
  },
  extraReducers: ({ addCase }) => {
    // Fetch events
    addCase(fetchEvents.pending, state => {
      state.isLoading = true;
      state.errorMessage = null;
    });
    addCase(fetchEvents.fulfilled, (state, { payload }) => {
      state.events = payload?.calendars;
      state.isLoading = false;
      state.errorMessage = null;
    });
    addCase(fetchEvents.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
    // Upload event
    addCase(uploadEvent.pending, state => {
      state.isLoading = true;
      state.upsertError = null;
      state.upsertSuccessful = false;
      state.deleteSuccessful = false;
    });
    addCase(uploadEvent.fulfilled, (state, { payload }) => {
      state.events = payload?.calendars;
      state.isLoading = false;
      state.upsertError = null;
      state.upsertSuccessful = true;
    });
    addCase(uploadEvent.rejected, (state, { error }) => {
      state.upsertError = error?.message ?? 'unknown error';
      state.isLoading = false;
      state.upsertSuccessful = false;
    });
    // Delete event
    addCase(removeEvent.pending, state => {
      state.isLoading = true;
      state.errorMessage = null;
      state.deleteSuccessful = false;
      state.upsertSuccessful = false;
    });
    addCase(removeEvent.fulfilled, (state, { payload }) => {
      state.events = payload?.calendars;
      state.isLoading = false;
      state.deleteSuccessful = true;
    });
    addCase(removeEvent.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export const { clearEvents } = eventsSlice.actions;

export default eventsSlice.reducer;

// log the pageview with their URL
import React from 'react';
import getConfig from 'next/config';

export const pageview = url => {
  window.gtag('config', process.env.GOOGLE_ANALYTICS_MEASURMENT_ID, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }) => {
  window.gtag('event', action, params);
};

export const GaInitializer = () => {
  const { publicRuntimeConfig } = getConfig();

  if (publicRuntimeConfig.GOOGLE_ANALYTICS_MEASURMENT_ID) {
    return (
      <>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GOOGLE_ANALYTICS_MEASURMENT_ID}`}
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${publicRuntimeConfig.GOOGLE_ANALYTICS_MEASURMENT_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </>
    );
  }

  return null;
};

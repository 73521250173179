import { createSlice } from '@reduxjs/toolkit';

import { fetchClients, updateClientStatus } from './asyncAction';

const initialState = {
  clients: null,
  isLoading: false,
  errorMessage: null,
};

const adminSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clearClients(state) {
      state.clients = null;
      state.isLoading = false;
      state.errorMessage = null;
    },
  },
  extraReducers: ({ addCase }) => {
    // Fetch Clients
    addCase(fetchClients.pending, state => {
      state.clients = null;
      state.isLoading = true;
      state.errorMessage = null;
    });
    addCase(fetchClients.fulfilled, (state, { payload }) => {
      state.clients = payload?.users;
      state.isLoading = false;
      state.errorMessage = null;
    });
    addCase(fetchClients.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
    // Update client status
    addCase(updateClientStatus.pending, state => {
      state.clients = null;
      state.isLoading = true;
      state.errorMessage = null;
    });
    addCase(updateClientStatus.fulfilled, (state, { payload }) => {
      state.clients = payload?.users;
      state.isLoading = false;
      state.errorMessage = null;
    });
    addCase(updateClientStatus.rejected, (state, { error }) => {
      state.errorMessage = error?.message ?? 'unknown error';
      state.isLoading = false;
    });
  },
});

export const { clearClients } = adminSlice.actions;

export default adminSlice.reducer;

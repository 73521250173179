import { REFRESH_TOKEN_ERROR } from './constants';

/**
 * Takes a token, and returns a new token with an updated
 * value and expiration. If an error occurs,
 * returns the old token and an error property
 */
export const refreshAccessToken = async token => {
  try {
    const url = `${process.env.AUTH0_ISSUER}/oauth/token`;
    const options = {
      client_id: process.env.AUTH0_CLIENT_ID,
      client_secret: process.env.AUTH0_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: token.refreshToken,
    };
    const body = Object.keys(options)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`,
      )
      .join('&');

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body,
    });

    if (!response.ok) {
      throw new Error(REFRESH_TOKEN_ERROR);
    }

    const refreshedTokens = await response.json();

    return {
      ...token,
      accessToken: refreshedTokens.access_token,
      expiresAt: Date.now() + refreshedTokens.expires_in * 1000,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
    };
  } catch (error) {
    // return the old token with an error set
    return {
      ...token,
      error: error.message,
    };
  }
};

const baseStyle = {
  px: 3,
  py: 0.5,
  height: '24px',
  textTransform: 'capitalize',
  fontSize: 'md',
  fontWeight: 600,
  borderRadius: '12px',
  lineHeight: '120%',
};

const variantSolid = props => {
  const { colorScheme } = props;

  const color = colorScheme === 'zeta' ? `${colorScheme}.900` : `white`;

  return {
    bg: `${colorScheme}.400`,
    color,
  };
};

const variantSubtle = props => {
  const { colorScheme } = props;

  return {
    bg: `${colorScheme}.50`,
    color: `${colorScheme}.800`,
  };
};

const variantOutline = props => {
  const { colorScheme } = props;
  const color =
    colorScheme === 'zeta' ? `${colorScheme}.600` : `${colorScheme}.400`;

  return {
    border: '1px solid',
    borderColor: color,
    color,
    bg: 'white',
  };
};

const variants = {
  solid: variantSolid,
  subtle: variantSubtle,
  outline: variantOutline,
};

const defaultProps = {
  variant: 'solid',
  colorScheme: 'alpha',
};

const BadgeStyles = {
  baseStyle,
  variants,
  defaultProps,
};

export default BadgeStyles;

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getArtists,
  getArtistStatistics,
  postArtistStatistics,
  deleteArtistStatistics,
  getArtistPosts,
  upsertArtistPosts,
  deleteArtistPosts,
  getTraffic,
  postTraffic,
  deleteTraffic,
} from '@/api/artists';

export const fetchArtists = createAsyncThunk(
  'admin/fetchArtists',
  async ({ search, showEggArtistsOnly }) => {
    const { data } = await getArtists({
      search,
      showEggArtistsOnly: +showEggArtistsOnly,
    });

    return data;
  },
);

export const fetchArtistStatistics = createAsyncThunk(
  'admin/fetchArtistStatistics',
  async ({ artistId, month, year, socialId }) => {
    const { data } = await getArtistStatistics({
      artistId,
      month,
      year,
      socialId,
    });

    return data;
  },
);

export const uploadArtistStatistics = createAsyncThunk(
  'admin/uploadArtistStatistics',
  async ({ artistId, socialId, statistics }) => {
    const { data } = await postArtistStatistics({
      artistId,
      socialId,
      statistics,
    });

    return data;
  },
);

export const removeArtistStatistics = createAsyncThunk(
  'admin/removeArtistStatistics',
  async ({ artistId, statisticIds, month, year, socialId }) => {
    await deleteArtistStatistics({ artistId, statisticIds });

    const { data } = await getArtistStatistics({
      artistId,
      month,
      year,
      socialId,
    });

    return data;
  },
);

export const fetchArtistPosts = createAsyncThunk(
  'admin/fetchArtistPosts',
  async ({ artistId, socialId, category }) => {
    const { data } = await getArtistPosts({
      artistId,
      socialId,
      category,
    });

    return data;
  },
);

export const uploadArtistPosts = createAsyncThunk(
  'admin/uploadArtistPosts',
  async ({ artistId, socialId, category, post }) => {
    await upsertArtistPosts({ artistId, post });

    const { data } = await getArtistPosts({
      artistId,
      socialId,
      category,
    });

    return data;
  },
);

export const removeArtistPosts = createAsyncThunk(
  'admin/removeArtistPosts',
  async ({ artistId, postId, socialId, category }) => {
    await deleteArtistPosts({ artistId, postId });

    const { data } = await getArtistPosts({
      artistId,
      socialId,
      category,
    });

    return data;
  },
);

export const fetchTraffic = createAsyncThunk(
  'admin/fetchTraffic',
  async ({ artistId, month, year, socialId }) => {
    const { data } = await getTraffic({ artistId, month, year, socialId });

    return data;
  },
);

export const uploadTraffic = createAsyncThunk(
  'admin/uploadTraffic',
  async ({ artistId, socialId, traffic }) => {
    const { data } = await postTraffic({ artistId, socialId, traffic });

    return data;
  },
);

export const removeTraffic = createAsyncThunk(
  'admin/removeTraffic',
  async ({ artistId, trafficIds, month, year, socialId }) => {
    await deleteTraffic({ artistId, trafficIds });

    const { data } = await getTraffic({ artistId, month, year, socialId });

    return data;
  },
);

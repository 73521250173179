
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { useStore } from '@/stores/store';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { REFRESH_SESSION_IN_SECONDS } from '@/utils/Auth';

import { AbilityContext, defaultAbility } from '@/utils/casl';
import { Authorized } from '@/utils/Auth/authorized';
import ThemeProvider from '@/providers/ThemeProvider';

import 'react-day-picker/dist/style.css';
import { GaInitializer, pageview } from '@/lib/ga';
import '../styles/fonts.css';

const EastGoesGlobalApp = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const [queryClient] = useState(() => new QueryClient());
  const store = useStore(pageProps.initialReduxState);

  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>{GaInitializer()}</Head>
      <QueryClientProvider client={queryClient}>
        <SessionProvider
          session={session}
          refetchInterval={REFRESH_SESSION_IN_SECONDS}
          refetchOnWindowFocus
        >
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider>
                <AbilityContext.Provider
                  value={session?.user?.ability ?? defaultAbility}
                >
                  <Authorized>
                    <Component {...pageProps} />
                  </Authorized>
                </AbilityContext.Provider>
              </ThemeProvider>
            </PersistGate>
          </ReduxProvider>
        </SessionProvider>
      </QueryClientProvider>
    </>
  );
};

EastGoesGlobalApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

EastGoesGlobalApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  pageProps: PropTypes.shape({
    session: PropTypes.shape({}),
    initialReduxState: PropTypes.shape({}),
  }),
};

const __Page_Next_Translate__ = EastGoesGlobalApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  
const colors = {
  black: '#000000',
  white: '#FFFFFF',
  eggRed: '#02095B',
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  alpha: {
    50: '#D9ECFF',
    100: '#BDD7FF',
    200: '#A0BFF7',
    300: '#83A5E6',
    400: '#6889D3',
    500: '#4D6EC1',
    600: '#345BAF',
    700: '#223C79',
    800: '#12226A',
    900: '#02095B',
  },
  beta: {
    50: '#FDFCF4',
    100: '#FBF9EA',
    200: '#F4F6D5',
    300: '#DEDFB4',
    400: '#C8C894',
    500: '#B2B173',
    600: '#9C9952',
    700: '#868232',
    800: '#707A19',
    900: '#5A6300',
  },
  gamma: {
    50: '#EBF5FF',
    100: '#DDEFFF',
    200: '#CEE9FC',
    300: '#B8D5F9',
    400: '#A2C1F5',
    500: '#8CACED',
    600: '#7699DE',
    700: '#6084CE',
    800: '#4A6FBE',
    900: '#345BAF',
  },
  delta: {
    50: '#F3FCF8',
    100: '#ECFBF1',
    200: '#D5E7EF',
    300: '#BED3EC',
    400: '#A7BFEA',
    500: '#90ABE7',
    600: '#7997E5',
    700: '#6283D3',
    800: '#4B6FC1',
    900: '#345BAF',
  },
  epsilon: {
    50: '#FDFCF4',
    100: '#FBF9EA',
    200: '#F4F6D5',
    300: '#DEDFB4',
    400: '#C8C894',
    500: '#B2B173',
    600: '#9C9952',
    700: '#868232',
    800: '#707A19',
    900: '#5A6300',
  },
  zeta: {
    50: '#FEFFFA',
    100: '#FDFFF2',
    200: '#FBFFE9',
    300: '#F9FFDF',
    400: '#F6FFD6',
    500: '#F4FFCC',
    600: '#EEFF99',
    700: '#E8FF66',
    800: '#E2FF33',
    900: '#DBFF00',
  },
};

export default colors;

import { mockArtistCategoriesArr, mockArtistsArr } from '@/utils/stubs/artists';
import { delay } from '@/utils/functions';

// This mock will be used to show the artist categories until we start fetching them from the backend
export const getArtistCategories = async () => {
  return {
    data: mockArtistCategoriesArr,
  };
};

export const createArtist = async () => {
  return true;
};

export const checkExistingArtist = async () => {
  return false;
};

export const getArtists = async () => {
  delay(100);

  return { data: mockArtistsArr };
};

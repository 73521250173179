export const mockArtistsArr = [
  {
    id: 1,
    name: 'Lady Gaga',
    status: 'ACTIVE',
    userArtistStatus: 'ACTIVE',
    avatarUrl:
      'https://egg-public-stg.s3.amazonaws.com/eef0d1dd-4912-432c-a422-17835805f5e1',
    managers: {
      active: [
        {
          id: 10,
          email: 'testam@test.com',
          firstName: 'Test',
          lastName: 'Artist Manager',
          roleId: 4,
        },
      ],
      inactive: [
        {
          id: 11,
          email: 'testam2@test.com',
          firstName: 'Test Inactive',
          lastName: 'Artist Manager',
          roleId: 4,
        },
      ],
    },
    categories: [{ id: 3, name: 'Artist' }],
    socials: [{ id: 1, name: 'Weibo', handler: 'Lady Gaga Weibo' }],
  },
  {
    id: 2,
    name: 'Doja Cat',
    status: 'PENDING',
    userArtistStatus: 'PENDING',
    avatarUrl:
      'https://egg-public-stg.s3.amazonaws.com/eef0d1dd-4912-432c-a422-17835805f5e1',
    managers: {
      active: [
        {
          id: 10,
          email: 'testam@test.com',
          firstName: 'Test',
          lastName: 'Artist Manager',
          roleId: 4,
        },
        {
          id: 11,
          email: 'testam2@test.com',
          firstName: 'Test Inactive',
          lastName: 'Artist Manager',
          roleId: 4,
        },
      ],
      inactive: [],
    },
    categories: [{ id: 3, name: 'Artist' }],
    socials: [{ id: 1, name: 'Weibo', handler: 'Doja Cat Weibo' }],
  },
];

export const mockArtistCategoriesArr = [
  {
    id: 1,
    name: 'Actor',
  },
  {
    id: 2,
    name: 'Model',
  },
  {
    id: 3,
    name: 'Artist',
  },
  {
    id: 4,
    name: 'Sports Team',
  },
  {
    id: 5,
    name: 'Influencer',
  },
  {
    id: 6,
    name: 'Gamer',
  },
  {
    id: 7,
    name: 'Athlete',
  },
  {
    id: 8,
    name: 'Brand',
  },
];

const baseStyle = {
  tab: {
    fontWeight: 'bold',
    fontSize: 'md',
  },
};

const variantLine = {
  tablist: {
    borderBottom: '2px solid',
    borderColor: 'inherit',
  },
  tab: {
    borderBottom: '2px solid',
    borderColor: 'gray.200',
    _selected: {
      borderColor: 'alpha.500',
      boxShadow: 'none',
      color: 'alpha.700',
    },
    _active: {
      boxShadow: 'none',
      bg: 'transparent',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
};

const variantEnclosed = {
  tab: {
    borderTopRadius: 'md',
    border: '2px solid',
    borderColor: 'transparent',
    mb: '-2px',
    _active: {
      boxShadow: 'none',
    },
    _selected: {
      boxShadow: 'none',
      color: 'alpha.500',
      borderColor: 'inherit',
    },
  },
  tablist: {
    mb: '-2px',
    borderBottom: '2px solid',
    borderColor: 'inherit',
  },
};

const variantSolidRounded = {
  tablist: {
    gap: 2,
  },
  tab: {
    borderRadius: 'full',
    border: 'none',
    color: 'alpha.700',
    bg: 'alpha.50',
    _active: {
      boxShadow: 'none',
    },
    _selected: {
      boxShadow: 'none',
      bg: 'alpha.500',
      border: 'none',
      color: 'white',
    },
  },
};

const variantSoftRounded = {
  tablist: {
    gap: 2,
    borderRadius: 'full',
  },
  tab: {
    borderRadius: 'full',
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'gray.600',
    _active: {
      boxShadow: 'none',
    },
    _selected: {
      boxShadow: 'none',
      bg: 'alpha.50',
      borderColor: 'transparent',
      color: 'gray.600',
    },
  },
};

const variants = {
  line: variantLine,
  enclosed: variantEnclosed,
  'solid-rounded': variantSolidRounded,
  'soft-rounded': variantSoftRounded,
};

const defaultProps = {
  size: 'md',
  variant: 'line',
};

const TabStyles = {
  baseStyle,
  variants,
  defaultProps,
};

export default TabStyles;

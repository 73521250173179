import getConfig from 'next/config';
import { signIn, signOut } from 'next-auth/react';
import { pages } from '@/utils/pages';
import { AUTH_PROVIDER, AUTH_LOGOUT_URL } from './constants';

const { publicRuntimeConfig } = getConfig();

const fullUrlFromPath = path => `${publicRuntimeConfig.BASE_URL}${path}`;

/**
 * Logins using the selected provider and redirects to the given path
 *
 * @param {string} path
 */
export const login = path => {
  signIn(AUTH_PROVIDER, { callbackUrl: fullUrlFromPath(path) });
};

/**
 * Triggers the signup screen for the given provider and redirects to the
 * selected path
 *
 * @param {string} path
 */
export const signUp = path => {
  signIn(
    AUTH_PROVIDER,
    { callbackUrl: fullUrlFromPath(path) },
    { screen_hint: 'signup' },
  );
};

/**
 * Logs out and redirects to the given returnUrl
 * The returnUrl given must be a valid logout url configured in the provider
 *
 * @param {string} returnUrl
 */
export const logout = async returnUrl => {
  const logoutUrl = `${AUTH_LOGOUT_URL}${fullUrlFromPath(
    returnUrl ?? pages.rootPath,
  )}`;

  localStorage.clear();
  signOut({ callbackUrl: logoutUrl });
};

/**
 * Next-auth refreshes the session on windows focus change, so this is a hack to trigger that behaviour
 */
export const reloadSession = () => {
  const event = new Event('visibilitychange');

  document.dispatchEvent(event);
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { patchUser, getUsers, deleteUser } from '@/api/user';

import { USER_ROLES } from '@/utils/constants/user';

export const fetchTeamMembers = createAsyncThunk(
  'teamMembers/fetchTeamMembers',
  async ({ order, search }) => {
    const { data } = await getUsers({
      orderBy: order,
      search,
      role: USER_ROLES.eggMembers,
    });

    return data;
  },
);

export const updateTeamMember = createAsyncThunk(
  'teamMembers/updateTeamMember',
  async ({ id, user }) => {
    const { data } = await patchUser({ id, user });

    return data;
  },
);

export const removeTeamMember = createAsyncThunk(
  'teamMembers/removeTeamMember',
  async id => await deleteUser(id),
);

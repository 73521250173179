export const saveFile = ({ blob, as: fileName }) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

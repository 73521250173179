export const STATUS_ERROR = { notFound: 404 };

export const DROPZONE_ERRORS = {
  fileTooLarge: 'file-too-large',
  invalidType: 'file-invalid-type',
};

export const VALIDATION_ERRORS = {
  emptyValues: 'emptyValues',
  requiredValue: 'requiredValue',
  sumError: 'sumError',
};
